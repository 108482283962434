import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from "react-router-dom";
import { Redirect } from 'react-router-dom'
import Loader from './Loader'
import { userType } from "../helper/constant"

import GuestLayout from '../layout/GuestLayout';
import StudentLayout from '../layout/StudentLayout';
import TutorLayout from '../layout/TutorLayout';

const Landing = lazy(() => import('../pages/home/landing'))
const SignUp = lazy(() => import('../pages/common/signup'))
const VerifyAccount = lazy(() => import('../pages/common/verifyAccount'))
const Login = lazy(() => import('../pages/common/login'))
const ForgotPassword = lazy(() => import('../pages/common/forgotPassword'))
const VerifyResetPassword = lazy(() => import('../pages/common/verifyResetPassword'))
const ResetPassword = lazy(() => import('../pages/common/resetPassword'))
const PrivacyPolicy = lazy(() => import('../pages/common/privacyPolicy'))
const LegalNotice = lazy(() => import('../pages/common/legalNotice'))
const Terms = lazy(() => import('../pages/common/terms'))
const AboutUs = lazy(() => import('../pages/common/aboutUs'))
const Faqs = lazy(() => import('../pages/common/faqs'))
const ContactUs = lazy(() => import('../pages/common/contactUs'))
const NotFound = lazy(() => import('../pages/common/notFound'))

const StudentProfile = lazy(() => import('../pages/student/studentProfile'))
const StudentDashboard = lazy(() => import('../pages/student/studentDashboard'))
const StudentPackages = lazy(() => import('../pages/student/ourPackages/packages'))
const StudentCheckout = lazy(() => import('../pages/student/ourPackages/checkout'))
const StudentTransaction = lazy(() => import('../pages/student/transaction'))
const StudentTransactionInvoice = lazy(() => import('../pages/student/transactionInvoice'))
const FindTutor = lazy(() => import('../pages/student/findTutor'))
const CreateCase = lazy(() => import('../pages/student/createCase'))
const CaseDetails = lazy(() => import('../pages/student/caseDetails'))
const TutorDetails = lazy(() => import('../pages/student/tutorDetails'))
const MyCases = lazy(() => import('../pages/student/myCases'))
const SubscribeCases = lazy(() => import('../pages/student/subscribeCases'))
const Lab = lazy(() => import('../pages/student/lab'))
const LabDetails = lazy(() => import('../pages/student/labDetails'))
const Material = lazy(() => import('../pages/student/material'))
const MaterialDetails = lazy(() => import('../pages/student/materialDetails'))
const CaseDashboardStudent = lazy(() => import('../pages/student/caseDashboard/caseDashboard'))

const TutorCaseDetails = lazy(() => import('../pages/tutor/tutorCaseDetails'))
const CaseDashboard = lazy(() => import('../pages/tutor/caseDashboard/caseDashboard'))
const TutorProfile = lazy(() => import('../pages/tutor/tutorProfile'))
const TutorLibrary = lazy(() => import('../pages/tutor/myLibrary'))
const Students = lazy(() => import('../pages/tutor/students'))
const StudentDetails = lazy(() => import('../pages/tutor/studentDetails'))
const TutorDashboard = lazy(() => import('../pages/tutor/tutorDashboard'))
const TutorLab = lazy(() => import('../pages/tutor/lab'))
const TutorMaterial = lazy(() => import('../pages/tutor/material'))
const TutorLabDetails = lazy(() => import('../pages/tutor/labDetails'))
const TutorMaterialDetails = lazy(() => import('../pages/tutor/materialDetails'))

class route extends Component {

    render() {
        const PublicRoute = ({ component: Component, ...rest }) => {
            return (
                <GuestLayout>
                    <Route {...rest} render={props => (
                        <Component {...props} />
                    )} />
                </GuestLayout>
            )
        }
        const GuestRoute = ({ component: Component, ...rest }) => {
            return (
                <GuestLayout>
                    <Route {...rest} render={props => (
                        (localStorage.getItem('userData') && localStorage.getItem('user_type') === userType.STUDENT)
                            ?
                            <Redirect to={{ pathname: '/student/dashboard', state: { from: props.location } }} />
                            :
                            (localStorage.getItem('userData') && localStorage.getItem('user_type') === userType.TUTOR)
                                ?
                                <Redirect to={{ pathname: '/tutor/dashboard', state: { from: props.location } }} />
                                :
                                <Component {...props} />
                    )} />
                </GuestLayout>
            )
        }
        const StudentRoute = ({ component: Component, ...rest }) => {
            return (
                <StudentLayout>
                    <Route {...rest} render={props => (
                        (localStorage.getItem('userData') && localStorage.getItem('user_type') === userType.STUDENT)
                            ?
                            <Component {...props} />
                            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )} />
                </StudentLayout>
            )
        }
        const TutorRoute = ({ component: Component, ...rest }) => {
            return (
                <TutorLayout>
                    <Route {...rest} render={props => (
                        (localStorage.getItem('userData') && localStorage.getItem('user_type') === userType.TUTOR)
                            ?
                            <Component {...props} />
                            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )} />
                </TutorLayout>
            )
        }

        return (

            <Suspense fallback={<Loader />}>
                <Switch>

                    <PublicRoute exact={true} path="/" component={Landing} />
                    <PublicRoute exact={true} path='/verify-account/:validateKey' component={VerifyAccount} />
                    <PublicRoute exact={true} path='/forgot-password' component={ForgotPassword} />
                    <PublicRoute exact={true} path='/reset-password-otp-verification' component={VerifyResetPassword} />
                    <PublicRoute exact={true} path='/reset-password' component={ResetPassword} />
                    <PublicRoute exact={true} path='/privacy-policy' component={PrivacyPolicy} />
                    <PublicRoute exact={true} path='/legal-notice' component={LegalNotice} />
                    <PublicRoute exact={true} path='/terms' component={Terms} />
                    <PublicRoute exact={true} path='/about-us' component={AboutUs} />
                    <PublicRoute exact={true} path='/faqs' component={Faqs} />
                    <PublicRoute exact={true} path='/contact-us' component={ContactUs} />

                    <GuestRoute exact={true} path='/register' component={SignUp} />
                    <GuestRoute exact={true} path='/login' component={Login} />

                    <StudentRoute exact={true} path='/student/profile' component={StudentProfile} />
                    <StudentRoute exact={true} path='/student/dashboard' component={StudentDashboard} />
                    <StudentRoute exact={true} path='/student/packages' component={StudentPackages} />
                    <StudentRoute exact={true} path='/student/checkout' component={StudentCheckout} />
                    <StudentRoute exact={true} path='/student/transaction' component={StudentTransaction} />
                    <StudentRoute exact={true} path='/student/transaction/:id' component={StudentTransactionInvoice} />
                    <StudentRoute exact={true} path='/student/find-tutor' component={FindTutor} />
                    <StudentRoute exact={true} path='/student/create-case/:tutorID' component={CreateCase} />
                    <StudentRoute exact={true} path='/student/case-details/:caseID' component={CaseDetails} />
                    <StudentRoute exact={true} path='/student/my-cases' component={MyCases} />
                    <StudentRoute exact={true} path='/student/subscribe-cases' component={SubscribeCases} />
                    <StudentRoute exact={true} path='/student/tutor-details/:tutorID' component={TutorDetails} />
                    <StudentRoute exact={true} path='/student/case-dashboard/:caseID' component={CaseDashboardStudent} />
                    <StudentRoute exact={true} path='/student/lab/:caseID' component={Lab} />
                    <StudentRoute exact={true} path='/student/material/:caseID' component={Material} />
                    <StudentRoute exact={true} path='/student/lab-details/:caseID' component={LabDetails} />
                    <StudentRoute exact={true} path='/student/material-details/:caseID' component={MaterialDetails} />

                    <TutorRoute exact={true} path='/tutor/profile' component={TutorProfile} />
                    <TutorRoute exact={true} path='/tutor/dashboard' component={TutorDashboard} />
                    <TutorRoute exact={true} path='/tutor/my-library' component={TutorLibrary} />
                    <TutorRoute exact={true} path='/tutor/students' component={Students} />
                    <TutorRoute exact={true} path='/tutor/students-details/:studentID' component={StudentDetails} />
                    <TutorRoute exact={true} path='/tutor/case-details/:caseID' component={TutorCaseDetails} />
                    <TutorRoute exact={true} path='/tutor/case-dashboard/:caseID' component={CaseDashboard} />
                    <TutorRoute exact={true} path='/tutor/lab/:caseID' component={TutorLab} />
                    <TutorRoute exact={true} path='/tutor/material/:caseID' component={TutorMaterial} />
                    <TutorRoute exact={true} path='/tutor/lab-details/:caseID' component={TutorLabDetails} />
                    <TutorRoute exact={true} path='/tutor/material-details/:caseID' component={TutorMaterialDetails} />

                    <Route exact={true} path='*' component={NotFound} />

                </Switch >
            </Suspense>
        )
    }
}

export default route;
