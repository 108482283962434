import { userConstants } from '../helper/constant';
export const userActions = {
    login,
    logout,
    addUser,
    editUser,
    switchLang
};
function login(user_type) {
    return dispatch => {
        localStorage.setItem("breadcrumb", []);
        localStorage.setItem("token", true);
        localStorage.setItem("user_type", user_type);
        dispatch({
            type: userConstants.LOGIN_SUCCESS
        })
    };

    // function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    // function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    // function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    return dispatch => {
        localStorage.clear();
        dispatch({ type: userConstants.LOGOUT });
    }
}

function switchLang(lang_code) {
    localStorage.setItem("default_lang_code", lang_code);
    return {
        type: userConstants.SWITCH_LANG,
        payload: {
            lang_code: lang_code
        }
    }
}

function addUser(user) {
    localStorage.setItem('userData', JSON.stringify(user));
    return {
        type: userConstants.ADD_USER,
        payload: {
            user: {
                ...user
            }
        }
    }
}

function editUser(user) {
    var localstorageObj = JSON.parse(localStorage.getItem('userData'));
    localStorage.setItem('userData', JSON.stringify(Object.assign({}, localstorageObj, user)));
    return {
        type: userConstants.EDIT_USER,
        payload: {
            user: {
                ...user
            }
        }
    }
}