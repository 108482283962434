import React, { Component } from 'react'
import Footer from '../pages/home/footer';
import '../loadCss/authpages'
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../../components/pages/home/header';
import { IntlProvider } from "react-intl";
import { flattenMessages } from '../container/util'
import messages_en from "../../translations/en.json"
import messages_es from "../../translations/es.json";
import { connect } from 'react-redux';

const messages = {
    'en': messages_en,
    'es': messages_es
};
class StudentLayout extends Component {
    componentWillUpdate() {
        window.scrollTo(0, 0);
    }
    componentWillMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <IntlProvider locale={this?.props?.lang_code ? this.props.lang_code : 'en'} messages={flattenMessages(messages[this?.props?.lang_code ? this.props.lang_code : 'en'])}>
                    {(window.location.pathname !== "/student/profile") &&
                        < div >
                            <Header />
                        </div>
                    }
                    {this.props.children}
                    <Footer />
                </IntlProvider>
            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        lang_code: state.authentication.lang_code,
    }
}
export default connect(mapStateToProps)(StudentLayout)

