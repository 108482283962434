import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { footerlogo } from '../../image'
import { connect } from 'react-redux';
import { userType } from "../../helper/constant";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages_en from "../../../translations/en.json";
import messages_es from "../../../translations/es.json";
import { flattenMessages } from '../../container/util'
import CallToNumberComponent from "../common/callComponent";
import MailToComponent from "../common/mailComponent";

const messages = {
	'en': messages_en,
	'es': messages_es
};
class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<IntlProvider locale={this?.props?.lang_code ? this.props.lang_code : 'en'} messages={flattenMessages(messages[this?.props?.lang_code ? this.props.lang_code : 'en'])}>
					<div className="footer-top">
						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-5 col-md-6">


									<div className="footer-widget footer-about">
										<div className="footer-logo">
											<img src={footerlogo} alt="logo" />
										</div>
										<div className="footer-about-content">
											<p>
												<FormattedMessage id="footer.footer_text" />
											</p>
											<div className="social-icon">
												<ul>
													<li>
														<a href="https://www.facebook.com/OnlineOrthodonticsAcademy/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
													</li>
													{/* <li>
														<a href="#0"><i className="fab fa-twitter"></i> </a>
													</li>
													<li>
														<a href="#0"><i className="fab fa-linkedin-in"></i></a>
													</li> */}
													<li>
														<a href="https://www.instagram.com/oorthodonticsacademy/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
													</li>
													{/* <li>
														<a href="#0"><i className="fab fa-dribbble"></i> </a>
													</li> */}
												</ul>
											</div>
										</div>
									</div>


								</div>

								<div className="col-lg-2 col-md-6">


									<div className="footer-widget footer-menu">
										<ul>
											{
												localStorage.getItem('user_type') === userType.STUDENT &&
												<React.Fragment>
													<li><Link to="/student/dashboard"><FormattedMessage id="global.home" /></Link></li>
													<li><Link to="/student/packages"><FormattedMessage id="global.our_packages" /></Link></li>
													<li><Link to="/student/find-tutor"><FormattedMessage id="global.find_tutor" /></Link></li>
												</React.Fragment>
											}
											{
												localStorage.getItem('user_type') === userType.TUTOR &&
												<React.Fragment>
													<li><Link to="/tutor/dashboard"><FormattedMessage id="global.home" /></Link></li>
													<li><Link to="/tutor/students"><FormattedMessage id="global.students" /></Link></li>
													<li><Link to="/tutor/my-library"><FormattedMessage id="global.my_library" /></Link></li>
												</React.Fragment>
											}
											{
												!localStorage.getItem('userData') &&
												<div>
													<li><Link to="/login"><FormattedMessage id="global.login" /></Link></li>
													<li><Link to="/register"><FormattedMessage id="global.register" /></Link></li>
												</div>
											}
										</ul>
									</div>


								</div>

								<div className="col-lg-2 col-md-6">
									<div className="footer-widget footer-menu">
										<ul>
											<li><Link to="/about-us"><FormattedMessage id="global.about_us" /></Link></li>
											<li><Link to="/contact-us"><FormattedMessage id="global.contact_us" /></Link></li>
											<li><Link to="/faqs"><FormattedMessage id="global.faqs" /></Link></li>
										</ul>
									</div>
								</div>

								<div className="col-lg-3 col-md-6">
									<div className="footer-widget footer-contact">
										<h2 className="footer-title"><FormattedMessage id="global.contact_us" /></h2>
										<div className="footer-contact-info">
											<div className="footer-address">
												<p><FormattedMessage id="footer.customer_service" /></p>
											</div>
											<p>
												<CallToNumberComponent
													tel_number="+34 689 987 800"
												>
													<FormattedMessage id="footer.tele_wa" /> : +34 689 987 800
												</CallToNumberComponent>
											</p>
											<p className="mb-0">
												<MailToComponent
													email="digicase@o-orthodontics.academy"
													subject="Your subject here...."
													body="Your description here..."
												>
													<FormattedMessage id="footer.email_us" />
												</MailToComponent>
											</p>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>



					<div className="footer-bottom">
						<div className="container-fluid">


							<div className="copyright pb-5 pr-4">
								<div className="row">
									<div className="col-md-6 col-lg-6">
										<div className="copyright-text">
											<p className="mb-0">&copy; <FormattedMessage id="footer.rights_reserved" /></p>
										</div>
									</div>
									<div className="col-md-6 col-lg-6">


										<div className="copyright-menu">
											<ul className="policy-menu">
												<li><Link to="/terms"><FormattedMessage id="global.terms" /></Link></li>
												<li><Link to="/legal-notice"><FormattedMessage id="global.legal" /></Link></li>
												<li><Link to="/privacy-policy"><FormattedMessage id="global.policy" /></Link></li>
											</ul>
										</div>


									</div>
								</div>
							</div>


						</div>
					</div>
				</IntlProvider>
			</footer>

		);
	}
};
const mapStateToProps = (state) => {
	return {
		loggedUserInfo: state.authentication,
		lang_code: state?.authentication?.lang_code
	}
}

export default connect(mapStateToProps, null)(Footer)
