import React, { Component } from 'react'
import Footer from '../pages/home/footer';
import '../loadCss/guestpages'
import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import Header from "../pages/home/header";
import { IntlProvider } from "react-intl";
import { flattenMessages } from '../container/util'
import messages_en from "../../translations/en.json"
import messages_es from "../../translations/es.json";
import { connect } from 'react-redux';

const messages = {
    'en': messages_en,
    'es': messages_es
};

class GuestLayout extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentWillMount() {
        window.scrollTo(0, 0);
    }

    componentWillUpdate() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <IntlProvider locale={this?.props?.lang_code ? this.props.lang_code : 'en'} messages={flattenMessages(messages[this?.props?.lang_code ? this.props.lang_code : 'en'])}>
                    <div>
                        {(window.location.pathname !== "/") &&
                            <Header />
                        }
                        {this.props.children}
                        <Footer />
                    </div>
                </IntlProvider>

            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        lang_code: state.authentication.lang_code,
    }
}
export default connect(mapStateToProps)(GuestLayout)