import axios from 'axios';
import { BASEAPIURL } from '../components/config/constants';

const lang = localStorage.getItem('default_lang_code') ? localStorage.getItem('default_lang_code') : 'en'

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': lang
};

const ApiUtils = {
    // user signup
    userSignup: function (params) {
        return axios
            .post(BASEAPIURL + "signup", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // verify account after signup
    userVerifyAccount: function (params) {
        return axios
            .get(BASEAPIURL + `verify_account/${params.email_auth_Key}`, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // login
    userLogin: function (params) {
        return axios
            .post(BASEAPIURL + "login", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // Social login
    userSocialLogin: function (params) {
        return axios
            .post(BASEAPIURL + "socialLogin", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // forgot Password
    userForgotPassword: function (params) {
        return axios
            .post(BASEAPIURL + "forgotPassword", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // verify otp for reset password
    userVerifyResetPasswordOtp: function (params) {
        return axios
            .post(BASEAPIURL + "verify_resetpassotp", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // reset password
    userResetPassword: function (params) {
        return axios
            .post(BASEAPIURL + "reset_password", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // change password
    userChangePassword: function (params) {
        return axios
            .post(BASEAPIURL + "change_password", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // static page
    getStaticPage: function (params) {
        return axios
            .post(BASEAPIURL + "static_page", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // faq page
    getFaqs: function (params) {
        return axios
            .get(BASEAPIURL + "list_faqs", { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get Country List
    getCountries: function (params) {
        return axios
            .get(BASEAPIURL + "country_list", { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get State List
    getStates: function (params) {
        return axios
            .get(BASEAPIURL + `state_list/${params.ID}`, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // contact us
    contactUs: function (params) {
        return axios
            .post(BASEAPIURL + "contact_us", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    support: function (params) {
        return axios
            .post(BASEAPIURL + "support", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // update user profile
    userUpdateProfile: function (params) {
        return axios
            .post(BASEAPIURL + "update_profile", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // logout
    userLogout: function (params) {
        return axios
            .post(BASEAPIURL + "logout", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // get package list
    getPackagePlan: function (params) {
        return axios
            .post(BASEAPIURL + "get_package_plan", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // get packages currency list
    getCurrencyList: function (params) {
        return axios
            .get(BASEAPIURL + "get_currency_list_packages", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // purchase a package
    purchasePackage: function (params) {
        return axios
            .post(BASEAPIURL + "purchase_package", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // create stripe payment intent
    createStripePaymentIntent: function (params) {
        return axios
            .post(BASEAPIURL + "create_stripe_payment_intent", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // cancel stripe payment intent
    cancelStripePaymentIntent: function (params) {
        return axios
            .post(BASEAPIURL + "cancel_stripe_payment_intent", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // transaction list
    getTransactionList: function (params) {
        return axios
            .post(BASEAPIURL + "get_transaction_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // coupon code
    checkCouponCode: function (params) {
        return axios
            .post(BASEAPIURL + "coupon_code_details", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // Transaction Details
    getTransactionDetails: function (params) {
        return axios
            .post(BASEAPIURL + "transaction_details", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },


    // Get transaction PDF
    getTransactionPDF: function (params) {
        return axios
            .post(BASEAPIURL + "get_invoice_pdf", params, {
                headers: headers,
                responseType: 'blob'
            })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // create case
    createCase: function (params) {
        return axios
            .post(BASEAPIURL + "create_case", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // delete image
    deleteImage: function (params) {
        return axios
            .post(BASEAPIURL + "delete_case_images", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // delete case voice note
    deleteCaseVoiceNote: function (params) {
        return axios
            .post(BASEAPIURL + "delete_case_voice_note", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get case details by case id
    getCaseDetails: function (params) {
        return axios
            .post(BASEAPIURL + `get_case_details`, params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get user list by user type
    getUserList: function (params) {
        return axios
            .post(BASEAPIURL + "get_user_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get user detail id
    getUserDetailByID: function (params) {
        return axios
            .get(BASEAPIURL + `get_user_details/${params.ID}`, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get my case list
    getMyCaseList: function (params) {
        return axios
            .post(BASEAPIURL + "get_tutor_my_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get other case list
    getOtherCaseList: function (params) {
        return axios
            .post(BASEAPIURL + "get_tutor_other_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // dashboard counts
    dashboardCounts: function (params) {
        return axios
            .get(BASEAPIURL + `count_case/${params.ID}`, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get subscribe case list by case id
    getSubscribeCaseListByCaseId: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/get_subscribe_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get subscribe case list by user_id
    getSubscribeCaseListByUserId: function (params) {
        return axios
            .post(BASEAPIURL + "get_subscribe_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get case list
    getCaseList: function (params) {
        return axios
            .post(BASEAPIURL + "get_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // subscribe case by case id
    subscribeCase: function (params) {
        return axios
            .post(BASEAPIURL + "subscribe_cases", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },

    // tutor dashboard count
    tutorDashboardCount: function (params) {
        return axios
            .get(BASEAPIURL + `tutor/count_case/${params.ID}`, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get case list of tutor
    tutorCaseList: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/get_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get my case list of tutor
    tutorMyCaseList: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/get_student_my_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get purchased case list
    getPurchasedCaseList: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/get_purchased_case_list", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get case type for dropdown in submit case
    getCaseTypes: function (params) {
        return axios
            .get(BASEAPIURL + "get_case_type", { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // update case 
    updateCase: function (params) {
        return axios
            .post(BASEAPIURL + "update_case", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // submit case 
    submitCase: function (params) {
        return axios
            .post(BASEAPIURL + "submit_case", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // update submit case 
    updateSubmitCase: function (params) {
        return axios
            .post(BASEAPIURL + "update_submit_case", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // add data for Lab
    addLabData: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/add_lab", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // view data for Lab
    getLabData: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/get_lab", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // view details for Lab
    getLabDetails: function (params) {
        return axios
            .post(BASEAPIURL + "get_lab_detail", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // view details for Material
    getMaterialDetails: function (params) {
        return axios
            .post(BASEAPIURL + "get_material_detail", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // add data for material
    addMaterialData: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/add_materials", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // view data for material
    getMaterialData: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/get_materials", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // get case list of tutor by case type
    tutorCaseListByCaseType: function (params) {
        return axios
            .post(BASEAPIURL + "tutor/get_case_list_by_type", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // student update case
    studentUpdateCase: function (params) {
        return axios
            .post(BASEAPIURL + "student_update_case ", params, { headers: headers })
            .then((response) => response.data)
            .catch((error) => {
                throw error.response.data;
            });
    },
    // fetch presinged URL for upload image
    getPresignedUrl: async function (params) {
        try {
            const response = await axios
                .post(BASEAPIURL + 'get_presigned_url', params, { headers: headers });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },

}
export default ApiUtils;