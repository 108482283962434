import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
const override = `
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px; /* margin is -0.5 * dimension */
    margin-top: -25px;
`;
// const override = `
//   display: block;
//   margin: 20% 50% 50% 45%;
// @media only screen and (max-width:479px) {
//       margin: 50% 50% 50% 40%;
// }`;

class Loader extends Component {
    render() {
        const color = "#0E94E7";
        const loading = "true";
        const speedMultiplier = 1;
        return (
            <BounceLoader
                color={color}
                loading={loading}
                css={override}
                size={100}
                speedMultiplier={speedMultiplier}
            />
        );
    }
}

export default Loader;
