import React, { Component } from 'react'

export class MailToComponent extends Component {
    render() {
        return (
            <a className='text-white' href={`https://mail.google.com/mail/?view=cm&fs=1&to=${this?.props?.email}&su=${this?.props?.subject}&body=${this?.props?.body}`} target="_blank" rel="noreferrer">{this?.props?.email}</a>
        )
    }
}

export default MailToComponent