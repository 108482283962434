/* S3 Upload Paths */
export const UPLOAD_PATH = "digicase/temp";
export const UPLOAD_CHAT_PHOTO_PATH = "digicase/chat";
export const UPLOAD_SUBMIT_CASE = "digicase/users";


/* API Base URL */
export const BASEAPIURL = process.env.REACT_APP_BASEAPIURL;

/* S3 Bucket */
export const BUCKET_CONST_URL = process.env.REACT_APP_BUCKET_CONST_URL;
export const BUCKET_URL = BUCKET_CONST_URL + "digicase/";
export const BUCKET_IMAGE_URL = BUCKET_URL + "assets/images/";