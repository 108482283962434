import React, { Component } from 'react'

export class CallToNumberComponent extends Component {
    render() {
        return (
            <a className='text-white' href={`tel:${this?.props?.tel_number}`}>{this?.props?.tel_number}</a>
        )
    }
}

export default CallToNumberComponent