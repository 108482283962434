import { BUCKET_IMAGE_URL } from './config/constants';

export const logo = BUCKET_IMAGE_URL + 'logo.png';
export const footerlogo = BUCKET_IMAGE_URL + 'footer-logo.png';

export const loginBanner = BUCKET_IMAGE_URL + 'login/login-banner.png';

export const db_icon_1 = BUCKET_IMAGE_URL + 'dashboard/icon-1.png';
export const db_icon_2 = BUCKET_IMAGE_URL + 'dashboard/icon-2.png';
export const db_icon_3 = BUCKET_IMAGE_URL + 'dashboard/icon-3.png';

export const doc_1 = BUCKET_IMAGE_URL + 'doctors/doc-1.png';
export const doc_2 = BUCKET_IMAGE_URL + 'doctors/doc-2.png';
export const doc_3 = BUCKET_IMAGE_URL + 'doctors/doc-3.png';

export const section5_img = BUCKET_IMAGE_URL + 'landing-page/section-5/main.jpg';

export const defaultImg = BUCKET_IMAGE_URL + 'case-images/camera.png';
export const defaultVideo = BUCKET_IMAGE_URL + 'case-images/video.png';
export const defaultPdf = BUCKET_IMAGE_URL + 'case-images/pdfImage.png';

export const profile = BUCKET_IMAGE_URL + 'user-dummy.png';
export const onError = BUCKET_IMAGE_URL + 'default.png';
