import { userConstants } from '../helper/constant';

const initialState = !!(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : {}
const lang_code = !!(localStorage.getItem('default_lang_code')) ? localStorage.getItem('default_lang_code') : "en"

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loginError: action.payload
            }
        case userConstants.LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            }
        case userConstants.ADD_USER:
            return {
                ...state,
                ...action.payload.user
            }
        case userConstants.SWITCH_LANG:
            return {
                ...state,
                lang_code: action.payload.lang_code
            }
        case userConstants.ADD_CASE_DETAILS:
            return {
                ...state,
                ...action.payload.data
            }
        case userConstants.EDIT_USER:
            return {
                ...state,
                ...action.payload.user
            }
        default:
            return { ...state, lang_code: lang_code }
    }
}