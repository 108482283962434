import { notification } from "antd";

function successNotification(message) {
    return notification.success({ message: "Success", description: message });
}
function errorNotification(message) {
    return notification.error({ message: "Error", description: message });
}
function infoNotification(message) {
    return notification.info({ message: "Alert", description: message });
}

/* set the dynamically breadcrumb */
function setBreadcrumb() {
    let breadcrumb = localStorage.getItem("breadcrumb") ? JSON.parse(localStorage.getItem("breadcrumb")) : [];
    let currentPath = window.location.pathname
    if (breadcrumb.length < 2) {
        if (currentPath !== breadcrumb[0] || breadcrumb.length === 0) { // check if the current path is not same as stored path
            breadcrumb.push(currentPath)//push the current path on array
        }
        localStorage.setItem("breadcrumb", JSON.stringify(breadcrumb));
    } else { //when navigate the new path
        if (currentPath !== breadcrumb[1]) {
            breadcrumb.shift(); //shift the path and push the new navigate path
            breadcrumb.push(currentPath)
        }
        localStorage.setItem("breadcrumb", JSON.stringify(breadcrumb));
    }
    if (breadcrumb.length > 1)
        return breadcrumb[0] //return the previous path which is shifted
}

//convert date time to utc
function convertDateTimeToUTC(date) {
    let yourDate = date
    //offset to maintain time zone difference
    const offset = yourDate.getTimezoneOffset();

    yourDate = new Date(yourDate.getTime() + (offset * 60 * 1000));
    let modifiedDate = yourDate.toISOString().split('T')[0] + " " + yourDate.toLocaleTimeString()

    return modifiedDate;
}

// convert date time as per local
function convertUTCToLocal(date) {

    let yourDate = new Date(date)
    //offset to maintain time zone difference
    // const offset = yourDate.getTimezoneOffset();

    // yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));
    return yourDate;
}

//format date 
function formatDate(date) {

    //convert utc to local
    let yourDate = new Date(date)

    //format date as per figma
    const formattedDate = yourDate.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
    })

    return formattedDate
}

//format date for case detail
function formatDateLong(date) {

    //convert utc to local
    let yourDate = new Date(date)

    //format date
    const formattedDate = yourDate.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'long', year: 'numeric'
    })

    return formattedDate
}

//format time
function formatTime(date) {

    //convert utc to local
    let yourDate = new Date(date)

    //format time as per figma
    const formattedTime = yourDate.toLocaleTimeString('en-US', {
        hour: 'numeric', minute: 'numeric'
    })

    return formattedTime
}

function formatTimeToSec(date) {

    //convert utc to local
    let yourDate = new Date(date)

    //format time as per figma
    const formattedTime = yourDate.toLocaleTimeString('en-US', {
        second: 'numeric'
    })

    return formattedTime
}

//remainig days count
function remainingDays(date) {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    return 4 - (Math.floor((new Date() - new Date(date)) / MS_PER_DAY))
}

//dashboard counts
function dashboardCount(value) {
    if (value > 0) {
        let x = value / 100;
        let y = Math.ceil(x)
        let z = y * 100
        return (value / z) * 100
    }
}

//count payable amount
function calculatePayableAmount(amount, discount) {
    if (amount > 0 && discount > 0) {
        let x = discount / 100;
        let y = (amount * x)
        return y.toFixed(2)
    }
}
export {
    setBreadcrumb,
    successNotification,
    errorNotification,
    infoNotification,
    convertDateTimeToUTC,
    convertUTCToLocal,
    formatDate,
    formatTime,
    formatDateLong,
    remainingDays,
    formatTimeToSec,
    dashboardCount,
    calculatePayableAmount
}