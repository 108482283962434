import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import '../../assets/css/bootstrap.css'
import '../../assets/css/fontawesome.min.css'
import '../../assets/css/fontawesome.css'
import '../../assets/css/style.css';
import '../../assets/css/style.scss';
import '../../assets/css/style-admin.css';
import '../../assets/css/tui-image.css'

const authTheme = () => (<React.Fragment></React.Fragment>);

export default authTheme;