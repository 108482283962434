export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'LOGOUT',
    ADD_USER: 'ADD_USER',
    EDIT_USER: 'EDIT_USER',
    ADD_CASE_DETAILS: 'ADD_CASE_DETAILS',
    SWITCH_LANG: 'SWITCH_LANG'
}
export const caseStatus = {
    ACTIVE: 'Active',
    COMPLETED: 'Completed',
}
export const userType = {
    STUDENT: "Student",
    TUTOR: "Tutor"
}
export const booleanType = {
    YES: 'Yes',
    NO: 'No'
}
export const platformType = {
    WEB: 'Web'
}

export const AppString = {
    DIGICASE_USER_ID: 'digicaseUserId',
    ID: 'id',
    NICKNAME: 'nickname',
    NODE_MESSAGES: 'messages',
    TASKS: "mail-task",
    NODE_USERS: 'users',
    UPLOAD_CHANGED: 'state_changed',
    DOC_ADDED: 'added',
    PREFIX_IMAGE: 'image/',
    DOC_REMOVED: 'removed'
}

export const RoutePath = {
    STUDENT_DASHBOARD: '/student/dashboard',
    STUDENT_CASE_DASHBOARD: '/student/case-dashboard',
    STUDENT_FIND_TUTOR: '/student/find-tutor',
    STUDENT_CASE_DETAILS: '/student/case-details',
    STUDENT_TRANSACTIONS: '/student/transaction',
    STUDENT_PACKAGES: '/student/packages',
    STUDENT_LAB: '/student/lab',
    
    TUTOR_DASHBOARD: '/tutor/dashboard',
    TUTOR_CASE_DASHBOARD: '/tutor/case-dashboard',
    TUTOR_STUDENTS: '/tutor/students',
    TUTOR_CASE_DETAILS: '/tutor/case-details',
    TUTOR_LAB: '/tutor/lab',
}