import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const config = {
    apiKey: "AIzaSyAGw6xWEAltKema_tLGjkgQuVO3MOD7_TA",
    authDomain: "digicase-6c3f6.firebaseapp.com",
    databaseURL: "https://digicase-6c3f6-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "digicase-6c3f6",
    storageBucket: "digicase-6c3f6.appspot.com",
    messagingSenderId: "621577077381",
    appId: "1:621577077381:web:c2fa3f5d23b3331cdcdfec",
    measurementId: "G-LHPKJKPKPJ"
};
// const config = {
//     apiKey: "AIzaSyCp52YTC1E1apmmJ8KMeHuqu7nbYLrAQWk",
//     authDomain: "chat-digicase.firebaseapp.com",
//     databaseURL: "https://chat-digicase-default-rtdb.asia-southeast1.firebasedatabase.app/",
//     projectId: 'chat-digicase',
//     storageBucket: 'chat-digicase.appspot.com',
//     messagingSenderId: '815493281693'
// };
firebase.initializeApp(config);
export const auth = firebase.auth;
export const db = firebase.database;

firebase.initializeApp(config)
firebase.firestore().settings({
    timestampsInSnapshots: true
})

export const myFirebase = firebase
export const myFirestore = firebase.firestore()
export const myStorage = firebase.storage
