import React, { Component } from 'react';
import Route from './components/container/route';
import { BrowserRouter as Router } from 'react-router-dom';
import CommonLayoutAuth from './components/helper/interceptor';

class App extends Component {
  render() {
    CommonLayoutAuth()
    let renderlayout = (
      <Route />
    );
    return (
      <div style={{ overflow: "hidden" }}>
        <Router>
          {renderlayout}
        </Router>
      </div>
    )
  }
}

export default App