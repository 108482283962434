import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { logo, profile, footerlogo } from "../../image";
import Dropdown from "react-bootstrap/Dropdown";
import { userActions } from '../../actions/user.action';
import { connect } from 'react-redux';
import { BUCKET_URL } from '../../config/constants';
import Api from '../../../Api/apiUtils';
import { Modal } from 'react-bootstrap';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleReactValidator from 'simple-react-validator';
import { errorNotification, successNotification } from '../../helper/helper';
import { userType } from '../../helper/constant';
import { withRouter } from 'react-router-dom'
import { myFirebase } from '../../services/fireBase'
import { injectIntl } from "react-intl";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {
                image: "",
                first_name: "",
                last_name: "",
                id: ""
            },
            lang: "ENGLISH",
            changePasswordData: {},
            image: profile,
            activeModal: null
        }
        const { formatMessage } = this.props.intl;

        this.validator = new SimpleReactValidator({
            messages: {
                required: formatMessage({ id: "validationMessages.REQUIRED_FIELD" }),
                in: formatMessage({ id: "validationMessages.PASSWORDMATCH" })
            },
            validators: {
                password_validate: {
                    message: formatMessage({ id: "validationMessages.VALIDPASSWORD" }),
                    rule: function (val, options) {
                        let regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
                        if (val.match(regexp)) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
            }
        });
    }
    componentDidMount() {
        this.setUserData()
        if (this.props.lang_code === "en") {
            this.setState({ lang: "ENGLISH" })
        } else if (this.props.lang_code === "es") {
            this.setState({ lang: "SPANISH" })
        }
    }
    switchLanguage = (lang) => {
        if (lang === "en") {
            this.setState({ lang: "ENGLISH" })
        } else if (lang === "es") {
            this.setState({ lang: "SPANISH" })
        }
        var _this = this;
        _this.props.switchLang(lang);
    }

    handleChange = (e) => {
        let inputData = {};
        inputData[e.target.name] = e.target.value;
        this.setState({ changePasswordData: Object.assign({}, this.state.changePasswordData, inputData) });
    }

    handleToggleModal = () => {
        this.setState({ activeModal: !this.state.activeModal })
    }

    onHandleMobileMenu = () => {
        let root = document.getElementsByTagName("html")[0];
        root.classList.add("menu-opened");
    };

    onhandleCloseMenu = () => {
        let root = document.getElementsByTagName("html")[0];
        root.classList.remove("menu-opened");
    };

    changePasswordHandler = () => {
        if (this.validator.allValid()) {
            this.setState({ isLoad: true });
            const data = JSON.parse(JSON.stringify(this.state.changePasswordData))
            data['id'] = this.props.loggedUserInfo.id;
            delete data.vconfirmPassword
            Api.userChangePassword(data).then(response => {
                this.setState({ isLoad: false });
                if (response.status === 200) {
                    successNotification(response.message);
                    this.handleToggleModal();
                    this.props.logout();
                    window.location.href = '/';
                } else {
                    errorNotification(response.message);
                }
            })
                .catch(err => {
                    this.setState({ isLoad: false });
                    if (err) {
                        errorNotification(err.message);
                    }
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    logout = () => {
        return new Promise((resolve, reject) => {
            myFirebase
                .auth()
                .signOut()
                .then(() => {

                    Api.userLogout({ id: this.state.userData.id }).then(response => {
                        if (response.status === 200) {
                            resolve(response);
                            successNotification(response.message);
                            this.props.logout();
                            window.location.href = '/';
                        } else {
                            errorNotification(response.message);
                        }
                    })
                        .catch(err => {
                            this.setState({ isLoad: false });
                            if (err) {
                                errorNotification(err.message);
                            }
                            reject(err)
                        });
                })
                .catch(err => {
                    this.setState({ isLoad: false });
                    if (err) {
                        errorNotification(err.message);
                    }
                    reject(err)
                });
        });
    }

    componentWillMount() {
        this.setUserData()
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        this.setUserData()
    }
    setUserData = () => {
        let userData = {};
        let lengthOfObject = Object.keys(this.props.loggedUserInfo).length
        if (lengthOfObject > 0) {
            userData['id'] = this.props.loggedUserInfo.id;
            userData['first_name'] = this.props.loggedUserInfo.first_name;
            userData['last_name'] = this.props.loggedUserInfo.last_name;
            userData['image'] = this.props.loggedUserInfo.image;
            userData['user_type'] = this.props.loggedUserInfo.user_type;
            this.setState({ userData: userData })
        }
    }
    render() {
        const { isLoad, lang, userData } = this.state;
        let pathnames = window.location.pathname
        const loggedInSession = localStorage.getItem('token');
        const { formatMessage } = this.props.intl;
        return (
            <React.Fragment>
                <header className="header">
                    <nav className="navbar navbar-expand-lg header-nav">
                        <div className="navbar-header">
                            <Link to="#" id="mobile_btn" onClick={() => this.onHandleMobileMenu()}>
                                <span className="bar-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </Link>
                            <Link to="/" className="navbar-brand logo">
                                <img src={window.location.pathname === "/" ? footerlogo : logo} className="img-fluid" alt="Logo" />
                            </Link>
                        </div>
                        <div className="main-menu-wrapper">
                            <div className="menu-header">
                                <Link to="/" className="menu-logo">
                                    <img src={logo} className="img-fluid" alt="Logo" />
                                </Link>
                                <a
                                    href="#0"
                                    id="menu_close"
                                    className="menu-close"
                                    onClick={() => this.onhandleCloseMenu()}
                                >
                                    <i className="fas fa-times"></i>
                                </a>
                            </div>
                            <ul className="main-nav">
                                {(loggedInSession)
                                    ? (
                                        <li className="login-link my-2">
                                            <img
                                                className="rounded-circle my-2 mx-3"
                                                src={(userData.image && userData.image !== "") ? BUCKET_URL + `users/${userData.id}/`
                                                    + userData.image : profile}
                                                onError={(e) => { e.target.onerror = null; e.target.src = `${this.state.image}` }}
                                                width="31"
                                                alt="Darren Elder"
                                            />
                                            <span>{userData.first_name + " " + userData.last_name}</span>
                                        </li>
                                    ) : (<></>)
                                }

                                {
                                    (userData && userData.user_type === userType.STUDENT) &&
                                    <React.Fragment>
                                        <li className={` ${pathnames.includes("dashboard") ? "active" : ""}`}>
                                            <Link to="/student/dashboard">{formatMessage({ id: "global.home" })}</Link>
                                        </li>
                                        <li className={` ${pathnames.includes("about-us") ? "active" : ""}`}>
                                            <Link to="/about-us">{formatMessage({ id: "global.about_us" })}</Link>
                                        </li>
                                        <li className={` ${pathnames.includes("/find-tutor") ? "active" : ""}`}>
                                            <Link to="/student/find-tutor">{formatMessage({ id: "global.find_tutor" })}</Link>
                                        </li>
                                        <li className={` ${pathnames.includes("/packages") ? "active" : ""}`}>
                                            <Link to="/student/packages">{formatMessage({ id: "global.our_packages" })}</Link>
                                        </li>
                                        <li className={` ${pathnames.includes("contact-us") ? "active" : ""}`}>
                                            <Link to="/contact-us">{formatMessage({ id: "global.contact_us" })}</Link>
                                        </li>
                                    </React.Fragment>
                                }

                                {
                                    userData.user_type === userType.TUTOR &&
                                    <React.Fragment>
                                        <li className={` ${pathnames.includes("dashboard") ? "active" : ""}`}>
                                            <Link to="/tutor/dashboard">{formatMessage({ id: "global.home" })}</Link>
                                        </li>
                                        <li className={` ${pathnames.includes("about-us") ? "active" : ""}`}>
                                            <Link to="/about-us">{formatMessage({ id: "global.about_us" })}</Link>
                                        </li>
                                        <li className={` ${(pathnames.includes("students") || pathnames.includes("students-details")) ? "active" : ""}`}>
                                            <Link to="/tutor/students">{formatMessage({ id: "global.students" })}</Link>
                                        </li>
                                        <li className={` ${(pathnames.includes("my-library") || pathnames.includes("subscribe-cases")) ? "active" : ""}`}>
                                            <Link to="/tutor/my-library">{formatMessage({ id: "global.my_library" })}</Link>
                                        </li>
                                        <li className={` ${pathnames.includes("contact-us") ? "active" : ""}`}>
                                            <Link to="/contact-us">{formatMessage({ id: "global.contact_us" })}</Link>
                                        </li>
                                    </React.Fragment>
                                }

                                {(loggedInSession)
                                    ? (
                                        <>
                                            <li className="login-link">
                                                <Link to={(this.state.userData.user_type === userType.TUTOR) ? "/tutor/profile" : "/student/profile"}>{formatMessage({ id: "global.my_profile" })}</Link>
                                            </li>
                                            {
                                                this.state.userData.user_type === userType.STUDENT &&
                                                <li className="login-link">
                                                    <Link to="/student/transaction">{formatMessage({ id: "global.my_transactions" })}</Link>
                                                </li>
                                            }


                                            <li className="login-link">
                                                {
                                                    this.state.userData.user_type === userType.TUTOR
                                                        ?
                                                        <Link to="/tutor/my-library">{formatMessage({ id: "global.my_library" })}</Link>
                                                        :
                                                        <Link to="/student/my-cases">{formatMessage({ id: "global.my_cases" })}</Link>

                                                }

                                            </li>
                                            {
                                                this.state.userData.user_type === userType.STUDENT &&
                                                <li className="login-link">
                                                    <Link to="/student/subscribe-cases">{formatMessage({ id: "global.subscribe_cases" })}</Link>
                                                </li>
                                            }
                                            <li className="login-link">
                                                <div onClick={() => this.handleToggleModal()}>{formatMessage({ id: "global.change_password" })}</div>
                                            </li>
                                            <li className="login-link">
                                                <div onClick={this.logout}>{formatMessage({ id: "global.logout" })}</div>
                                            </li>
                                        </>
                                    ) : (
                                        <li className="login-link">
                                            <Link to="/login">{formatMessage({ id: "header.start_now" })}</Link>
                                        </li>
                                    )}
                            </ul>
                        </div>
                        <ul className="nav header-navbar-rht">

                            <Dropdown className="user-drop en-dropdown nav-item dropdown has-arrow logged-item top-en">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <div className="header-contact-detail">
                                        <p className="contact-header">{lang}</p>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="arrow-up"></div>
                                    <Dropdown.Item onClick={() => this.switchLanguage('en')} >
                                        ENGLISH
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.switchLanguage('es')} >
                                        SPANISH
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <li className="nav-item contact-item">
                                    <div className="header-contact-detail">
                                        <p className="contact-header">english
                                            <i className="fas fa-chevron-down ml-2 mt-1" aria-hidden="true"></i>
                                        </p>
                                    </div>
                                </li> */}

                            {(loggedInSession)
                                ? (
                                    <>
                                        <Dropdown className="user-drop nav-item dropdown has-arrow logged-item">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <span className="avatar avatar-sm ml-2">
                                                    <img
                                                        className="rounded-circle"
                                                        src={(userData.image && userData.image !== "") ? BUCKET_URL + `users/${this.state.userData.id}/`
                                                            + userData.image : profile}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = `${this.state.image}` }}
                                                        width="31"
                                                        alt="User"
                                                    />
                                                </span>
                                                <div className="header-contact-detail">
                                                    <p className="contact-header">{userData.first_name}</p>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className="arrow-up"></div>
                                                <Dropdown.Item href={(this.state.userData.user_type === userType.TUTOR) ? "/tutor/profile" : "/student/profile"}>
                                                    {formatMessage({ id: "global.my_profile" })}
                                                </Dropdown.Item>
                                                {
                                                    this.state.userData.user_type === userType.STUDENT &&
                                                    <Dropdown.Item href="/student/transaction">
                                                        {formatMessage({ id: "global.my_transactions" })}
                                                    </Dropdown.Item>
                                                }

                                                {
                                                    this.state.userData.user_type === userType.TUTOR
                                                        ?
                                                        <Dropdown.Item href="/tutor/my-library">{formatMessage({ id: "global.my_library" })}</Dropdown.Item>
                                                        :
                                                        <Dropdown.Item href="/student/my-cases">{formatMessage({ id: "global.my_cases" })}</Dropdown.Item>

                                                }
                                                {
                                                    this.state.userData.user_type === userType.STUDENT &&
                                                    <Dropdown.Item href="/student/subscribe-cases">
                                                        {formatMessage({ id: "global.subscribe_cases" })}
                                                    </Dropdown.Item>
                                                }
                                                <Dropdown.Item onClick={() => this.handleToggleModal()}>{formatMessage({ id: "global.change_password" })}</Dropdown.Item>
                                                <Dropdown.Item onClick={this.logout}>{formatMessage({ id: "global.logout" })}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                ) : (
                                    <>
                                        <li className="nav-item">
                                            <Link to="/login" className="nav-link header-login">
                                                {formatMessage({ id: "header.start_now" })} {" "}
                                            </Link>
                                        </li>{" "}
                                    </>
                                )}
                        </ul>
                    </nav>
                </header>

                {/* ------------------------------------- model for change password -------------------------------- */}
                <Modal show={this.state.activeModal} onHide={this.handleToggleModal} centered>
                    <Modal.Body>
                        <div className='text-right'>
                            <i className="  cursor-pointer" onClick={this.handleToggleModal}><ClearIcon /></i>
                        </div>
                        <div className="payment-widget px-3 pb-5  ">
                            <h4 className="payment-card-title py-3">{formatMessage({ id: "global.change_password" })}</h4>
                            <div className="payment-list">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group form-focus form-group-password">
                                            <input type="password" className="form-control floating" name="vOldPassword" placeholder="" value={this.state.changePasswordData.vOldPassword} onChange={this.handleChange} required />
                                            <label className="focus-label">{formatMessage({ id: "header.old_password" })}</label>
                                            {this.validator.message(`${formatMessage({ id: "header.old_password" })}`, this.state.changePasswordData.vOldPassword, "required|password_validate", { className: 'text-danger' })}
                                        </div>
                                        <div className="form-group form-focus form-group-password">
                                            <input type="password" className="form-control floating" name="vNewPassword" placeholder="" value={this.state.changePasswordData.vNewPassword} onChange={this.handleChange} required />
                                            <label className="focus-label">{formatMessage({ id: "header.new_password" })}</label>
                                            {this.validator.message(`${formatMessage({ id: "header.new_password" })}`, this.state.changePasswordData.vNewPassword, "required|password_validate", { className: 'text-danger' })}
                                            <div className="password-note">{formatMessage({ id: "validationMessages.PASSWORD" })}</div>
                                        </div>
                                        <div className="form-group form-focus">
                                            <input type="password" className="form-control floating" name="vConfirmPassword" placeholder="" value={this.state.changePasswordData.vConfirmPassword} onChange={this.handleChange} required />
                                            <label className="focus-label">{formatMessage({ id: "header.confirm_password" })}</label>
                                            {this.validator.message(`${formatMessage({ id: "header.confirm_password" })}`, this.state.changePasswordData.vConfirmPassword, `required|in:${this.state.changePasswordData.vNewPassword}`, { className: 'text-danger' })}
                                        </div>
                                        <button className="btn btn-primary btn-block btn-lg login-btn mt-3" type="submit" onClick={this.changePasswordHandler} disabled={isLoad}>
                                            {isLoad &&
                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={{ fontSize: "15px", marginRight: "5px" }}></i>
                                            }
                                            {<span>{formatMessage({ id: "global.change_password" })}</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment >
        );
    };
}
const mapStateToProps = (state) => {
    return {
        loggedUserInfo: state.authentication,
        lang_code: state.authentication.lang_code,
    }
}

const actionCreators = {
    logout: userActions.logout,
    switchLang: userActions.switchLang
};
export default injectIntl(withRouter(connect(mapStateToProps, actionCreators)(Header)))