import axios from "axios";
import { BASEAPIURL } from '../config/constants';

export default function CommonLayoutAuth() {
    axios.interceptors.request.use(
        config => {
            const data = JSON.parse(localStorage.getItem("userData"));
            if (data !== null) {
                config.headers['token'] = data.auth_token;
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });

    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };


    axios.interceptors.response.use((response) => {
        return response
    }, (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['token'] = token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }
            originalRequest._retry = true;
            isRefreshing = true;

            let data = JSON.parse(localStorage.getItem('userData'));

            return axios.post(BASEAPIURL + 'token/refresh',
                {
                    vRefreshToken: data.vRefreshToken,
                    id: data.id
                })
                .then(res => {
                    if (res.status === 200) {
                        data.vRefreshToken = res.data.data.refreshToken;
                        data.auth_token = res.data.data.auth_token;
                        // store.dispatch(editUser({ auth_token: res.data.data.auth_token, vRefreshToken: res.data.data.refreshToken }))
                        localStorage.setItem('userData', JSON.stringify(data));
                        // originalRequest.headers['token'] = data.auth_token;
                        processQueue(null, res.data.data.auth_token);
                        return axios(originalRequest);
                    } else if (res.status === 500) {
                        window.location.href = '/';
                        localStorage.clear();
                    }
                })
                .finally(() => {
                    isRefreshing = false;
                });
        }
        else if (error.response.status === 403 && originalRequest.url ===
            BASEAPIURL + 'token/refresh') {
            window.location.href = '/';
            localStorage.clear();
            return Promise.reject(error);
        }
        else if (error.response.status === 500 && originalRequest.url ===
            BASEAPIURL + 'token/refresh') {
            window.location.href = '/';
            localStorage.clear();
            return Promise.reject(error);
        }
        return Promise.reject(error);
    });
}